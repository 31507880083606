import { Contract, PriceRelease } from '@/types/excel-addin';
import dayjs from 'dayjs';
import { flatten } from 'lodash';

function formatDateToMonth(dateStr: string){
    return dayjs(dateStr).format('MMM')
}

export const convertToExcel = (
  contract: Contract,
  priceReleases: PriceRelease[],
  unit = 'usdPerDay'
) => {
  // return ["30/5/22","Spark25 Pacific 160 TFDE","Spark25S","spot","SparkS","14/6/22","14/7/22","","65","250","82500","73000","107250","","","","","",""]
  const result = [];
  for (const pr of priceReleases) {
    for (const d of pr.data) {
      for (const dp of d.dataPoints) { 
        let priceByUnit = unit === 'usdPerDay' ? dp.derivedPrices.usdPerDay : dp.derivedPrices.usdPerMMBtu;
        if (unit === 'usdPerDay' && !priceByUnit) {
          priceByUnit = dp.derivedPrices.usdPerMMBtu;
        }
        result.push( [
          (pr.releaseDate),
          contract.contractGroupName,
          contract.tickerSymbol,
          contract.type,
          dp.deliveryPeriod.name,
          (dp.deliveryPeriod.startAt),
          (dp.deliveryPeriod.endAt),
          formatDateToMonth(dp.deliveryPeriod.startAt),
          dp.yourAssessedPrice?.value,
          priceByUnit?.spark,
          priceByUnit?.sparkMin,
          priceByUnit?.sparkMax,
          priceByUnit?.portfolioPlayer,
          priceByUnit?.portfolioPlayerMin,
          priceByUnit?.portfolioPlayerMax,
          priceByUnit?.shipOwner,
          priceByUnit?.shipOwnerMin,
          priceByUnit?.shipOwnerMax,
        ].map(colVal => colVal ?? ""))
      }
    }
  }
  return result;
};
