<script lang="ts" setup>
import { useRouter } from 'vue-router/composables';



const props = defineProps<{ route?: RouteComponent; title: string }>()
const router = useRouter()

</script>
<template>
  <header class="flex gap-3 items-center">
    <router-link v-if="props.route" class="text-green flex gap-4 items-center" :to="props.route">
      <SparkIcon icon="chevronleft" class="mb-[2px]" />
      <div>
        <span class="text-black font-bold text-xl font-display">{{
        title
        }}</span>
      </div>
    </router-link>
    <a v-else class="text-green flex gap-4 items-center" @click="router.go(-1)">
      <SparkIcon icon="chevronleft" class="mb-[2px]" />
      <div>
        <span class="text-black font-bold text-xl font-display">{{
        title
        }}</span>
      </div>
    </a>
  </header>
</template>
