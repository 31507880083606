import { RouteConstants } from '#/config/route'
import { useAccountsStore } from '@/stores/accounts'
import { useNotificationsStore } from '@/stores/notifications'
import { NavigationGuard } from 'vue-router'
import { ROUTE_NAMES } from '../route-names'

export const authGuard: NavigationGuard = async (to, from, next) => {
  const accountsStore = useAccountsStore()
  const isLoggedIn = accountsStore.isLoggedIn
  switch (to.meta?.requireAuth) {
    case RouteConstants.ROUTE_ACCESS.REQUIRES_NO_LOGIN:
      if (!isLoggedIn) {
        next()
      } else {
        next({ name: ROUTE_NAMES.HOME })
      }
      break
    case RouteConstants.ROUTE_ACCESS.REQUIRES_LOGIN:
      if (isLoggedIn) {
        next()
      } else {
        useNotificationsStore().pushClientNotification({
          message: 'Login required to visit this page.',
          level: 'info',
        })
        next({ name: ROUTE_NAMES.LOGIN, query: { to: to.name } })
      }
      break
    case RouteConstants.ROUTE_ACCESS.ALL:
    default:
      next()
  }
}
