<script lang="ts" setup>
import { computed } from 'vue';
type CrumbConfig = {
  icon?: string;
  name: string;
  value?: any;
  styleClasses?: string;
}
const props = defineProps<{
  config: CrumbConfig[]
}>();

const emit = defineEmits<{
  (e: 'input', value: any): void
}>()

const FIRST_NODE_STYLE = 'bg-gray-900 text-white pl-2 pr-4';
const MID_NODE_STYLE = 'bg-gray-600 text-white pr-4 pl-[1rem]';
const LAST_NODE_STYLE = 'bg-green-500 text-white pr-4 pl-[1rem]';

const computedCrumbs = computed(() => {
  return props.config.map((c, index) => {
    let styleClasses = '';
    if (index === 0) {
      styleClasses = FIRST_NODE_STYLE + ' ' + c.styleClasses;
    } else if (index === props.config.length - 1) {
      styleClasses = LAST_NODE_STYLE + ' ' + c.styleClasses;
    } else {
      styleClasses = MID_NODE_STYLE + ' ' + c.styleClasses;
    }
    return {
      ...c,
      styleClasses,
    };
  });
});
</script>
<template>
  <nav class="ribbon w-full flex flex-wrap">
    <a :route="crumb.route" v-for="(crumb, $index) in computedCrumbs" @click="emit('input', crumb.value)" class="
        ribbon-item
        -ml-1
        mt-2
        py-1
        pl-6
        pr-2
        font-bold
        flex
        items-center
        gap-1
        relative
        rounded-lg
        hover:text-gray-200
        hover:underline
      " :class="[
        crumb.styleClasses,
      ]">
      <SparkIcon class="inline-block text-white" v-if="crumb.icon" size="10" :icon="crumb.icon" />
      {{ crumb.name }}
    </a>
  </nav>
</template>
<style lang="scss" scoped>
$arrow-length: 9px;


.ribbon-item:not(:first-child):not(:last-child) {
  clip-path: polygon(0 0,
      $arrow-length 50%,
      0 100%,
      calc(100% - $arrow-length) 100%,
      100% 50%,
      calc(100% - $arrow-length) 0);
}


.ribbon-item:last-child {
  clip-path: polygon(0 0,
      $arrow-length 50%,
      0 100%,
      100% 100%,
      100% 0)
}

.ribbon-item:first-child {
  clip-path: polygon(0 0,
      0 100%,
      calc(100% - $arrow-length) 100%,
      100% 50%,
      calc(100% - $arrow-length) 0);
}
</style>
