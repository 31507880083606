<script lang="ts" setup>
import { computed } from 'vue'

const CLASS_MAP = {
  primary: {
    primary: 'bg-primary text-primary-text hover:bg-primary-400',
    green: 'bg-green text-white hover:bg-green-400',
    access: 'bg-access text-black hover:bg-access-400',
    orange: 'bg-orange-500 text-white hover:bg-orange-400',
    blue: 'bg-blue text-white hover:bg-blue-400',
    red: 'bg-red text-white hover:bg-red-400',
    basis: 'bg-basis text-white hover:bg-basis-400',
    // kpler: 'bg-[#29333c] text-white hover:bg-[#EE5B11]',
    kpler: 'bg-[#EE5B11] text-white hover:bg-[#d6520f] ',
  },
  secondary: {
    primary:
      'border-1 border-primary text-primary-500 hover:text-primary-400 hover:border-primary-400',
    green:
      'border-1 border-green text-green-500 hover:text-green-400 hover:border-green-400',
    blue: 'border-1 border-blue text-blue-500 hover:text-blue-400 hover:border-blue-400',
    gray: 'border-1 border-gray-800 text-gray-500 hover:text-gray-400 hover:border-gray-400',
    basis:
      'border-1 border-basis-800 text-basis-500 hover:text-basis-400 hover:border-basis-400',
    kpler:
      'border-2 border-[#EE5B11] bg-shade-light text-[#EE5B11] hover:bg-[#EE5B11] hover:text-white',
  },
  static: {
    green: 'border-1 border-gray-300 text-gray-700 hover:bg-gray-50',
  },
  link: {
    green: 'text-green-500 hover:text-green-400',
    blue: 'text-blue-500 hover:text-blue-400',
    black: 'text-gray-700 hover:bg-gray-700 hover:text-white',
  },
}
const props = withDefaults(
  defineProps<{
    color?: string
    loading?: boolean
    disabled?: boolean
    styleClass?: string | string[] | object
    size?: 'xs' | 'sm' | 'md' | 'lg'
    type?: 'primary' | 'secondary' | 'static' | 'link'
  }>(),
  {
    color: 'green',
    loading: false,
    disabled: false,
    size: 'md',
    styleClass: '',
    type: 'primary',
  }
)

const loadingClass = computed(() => {
  if (props.loading) {
    return 'cursor-wait opacity-50'
  } else {
    return ''
  }
})
const disabledClass = computed(() => {
  if (props.disabled) {
    return 'cursor-not-allowed opacity-50'
  } else {
    return ''
  }
})

// @Prop({ default: false }) loading!: boolean;
// @Prop({ default: false }) disabled!: boolean;
// @Prop({ default: '' }) styleClass!: string;
// @Prop({ default: 'primary' }) type!:
//   | 'primary'
//   | 'secondary'
//   | 'link'
//   | 'static';

const sizeClass = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'text-xs py-2 px-2'
    case 'sm':
      return ''
    case 'md':
      return 'text-base py-3'
    case 'lg':
      return 'text-lg py-4'
    default:
      return 'py-5 text-lg'
  }
})
const classes = computed(() => {
  return CLASS_MAP[props.type][props.color]
})
</script>

<template>
  <button
    class="select-none font-medium rounded-lg py-2 px-5 h-max flex items-center justify-center leading-none disabled:cursor-not-allowed ring-0 disabled:opacity-60"
    :class="[classes, styleClass, sizeClass, disabledClass, loadingClass]"
    :disabled="loading || disabled"
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <div v-if="loading">
      <SparkIcon
        icon="spinner"
        size="16"
        spin
        class="text-gray-200 mr-1"
      ></SparkIcon>
    </div>
    <slot />
  </button>
</template>
