<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'

import PageHeader from '@/components/common/PageHeader.vue'
import SparkBreadcrumb from '@/components/generic/SparkBreadcrumb.vue'
import { ROUTE_NAMES } from '@/router/route-names'
import { useMainStore } from '@/stores'
import { useAccessStore } from '@/stores/access'

import { generateId } from './utils'

const priceReleasesOptions = [
  {
    text: 'Latest',
    value: 'latest'
  },
  {
    text: 'Last n Releases',
    value: 'last-n'
  },
  {
    text: 'All',
    value: 'all'
  }
]

const lastNReleaseNumber = ref(30)

const state = reactive<{
  checkedList: string[]
  priceReleasesFilter: string
  submitting: boolean
  errors: string[]
}>({
  checkedList: [] as string[],
  priceReleasesFilter: 'latest',
  submitting: false,
  errors: [] as string[]
})

const accessStore = useAccessStore()
const mainStore = useMainStore()

async function excelClearTable(
  context: Excel.RequestContext,
  tableName: string
) {
  const sheet = context.workbook.worksheets.getActiveWorksheet()

  sheet.tables.getItem(tableName).delete()
  sheet.activate()

  await context.sync()
}

async function loadData() {
  state.submitting = true
  // excelClearTable()

  state.errors = []
  let columns: string[] = []
  let rows: string[][] = []
  try {
    if (state.priceReleasesFilter === 'latest') {
      const result = await accessStore.fetchLatestSparkRCsv()
      columns = result.columns
      rows = result.rows
    } else if (state.priceReleasesFilter === 'last-n') {
      const result = await accessStore.fetchLastNSparkRCsv(
        lastNReleaseNumber.value
      )
      columns = result.columns
      rows = result.rows
    } else if (state.priceReleasesFilter === 'all') {
      const result = await accessStore.fetchAllSparkRCsv()
      columns = result.columns
      rows = result.rows
    }

    pasteTableOntoExcel(columns, rows)
  } finally {
    state.submitting = false
  }
}

function pasteTableOntoExcel(columns: string[], rows: string[][]) {
  try {
    if (!window.Excel) {
      console.warn('Excel is not loaded')
      return
    }

    window.Excel.run(async (context) => {
      const workbook = context.workbook
      const sheet = workbook.worksheets.getActiveWorksheet()
      sheet.load('tables')
      await context.sync()
      const existingTableCollection = sheet.tables
      const existingTables = existingTableCollection.items

      const activeRange = context.workbook.getSelectedRange()
      const firstCell = activeRange.getCell(0, 0)

      const columnsCount = columns.length
      const rowsCount = rows.length

      const tableOccupyRange = firstCell.getAbsoluteResizedRange(
        rowsCount,
        columnsCount
      )

      // clear existing tables in current cell
      if (existingTables) {
        for (const existingTable of existingTables) {
          const range = existingTable.getRange()
          const intersection =
            range.getIntersectionOrNullObject(tableOccupyRange)
          try {
            await context.sync()
          } catch (err) {
            state.errors.push(JSON.stringify(err))
          }
          if (!intersection.isNullObject) {
            await excelClearTable(context, existingTable.name)
          }
        }
      }

      const TABLE_NAME = 'DataTable' + generateId()

      const tableRange = firstCell.getAbsoluteResizedRange(1, columnsCount)
      const dataTable = sheet.tables.add(tableRange, true /* hasHeaders */)
      dataTable.name = TABLE_NAME
      dataTable.getHeaderRowRange().values = [columns]

      // Styles
      dataTable.style = 'TableStyleLight1'
      dataTable.getHeaderRowRange().format.fill.color = '#21C08E'
      dataTable.getHeaderRowRange().format.font.color = '#FFFFFF'
      dataTable.getHeaderRowRange().format.font.size = 12
      dataTable.getHeaderRowRange().format.font.name = 'Mont'
      dataTable.getHeaderRowRange().format.font.bold = true

      dataTable.getDataBodyRange().format.fill.color = '#FFFFFF'
      dataTable.getDataBodyRange().format.font.color = '#000000'
      dataTable.getDataBodyRange().format.font.name = 'Roboto'
      dataTable.getDataBodyRange().format.font.bold = false

      dataTable.rows.add(
        undefined /* add rows to the end of the table */,
        rows,
        false
      )

      if (Office.context.requirements.isSetSupported('ExcelApi', '1.2')) {
        sheet.getUsedRange().format.autofitColumns()
        sheet.getUsedRange().format.autofitRows()
      }

      try {
        sheet.activate()
        await context.sync()
      } catch (err: any) {
        state.errors.push(err.message)
      }
    })

    // analyticsTrack('RUN_EXCEL_ADDIN', {
    //   contracts: selectedContracts.map((c) => c.id),
    //   priceReleasesFilter: state.priceReleasesFilter,
    // })
  } catch (err: any) {
    state.errors.push(err.message)
  } finally {
    state.submitting = false
  }
}

const router = useRouter()

function handleBreadcrumbInput(value: string) {
  router.push({ name: value })
}
</script>

<template>
  <Loader :modelValue="mainStore.isLoading">
    <div class="p-5 space-y-10">
      <section class="space-y-6">
        <PageHeader :title="`Get Access Data`" />
        <SparkBreadcrumb
          :config="[
            { name: 'Products', icon: 'home-filled', value: ROUTE_NAMES.HOME },
            {
              name: 'Access',
              icon: 'accessLogo',
              styleClasses: `bg-access text-black`
            }
          ]"
          @input="handleBreadcrumbInput"
        />
      </section>
      <a-form layout="vertical">
        <a-form-item :colon="false" label="Releases">
          <a-radio-group
            class="w-full"
            v-model:value="state.priceReleasesFilter"
            size="small"
          >
            <a-radio-button
              v-for="opt in priceReleasesOptions"
              :value="opt.value"
              :key="opt.value"
              >{{ opt.text }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          v-if="state.priceReleasesFilter === 'last-n'"
          :colon="false"
          label="Number of releases"
        >
          <a-input
            class="w-full"
            v-model:value="lastNReleaseNumber"
            size="small"
          />
        </a-form-item>
        <!-- show errors -->
        <a-form-item v-if="state.errors.length">
          <div class="text-red-500 space-y-3">
            <a-alert
              :key="error"
              type="error"
              v-for="error in state.errors"
              message="Warning"
              :description="error"
            />
          </div>
        </a-form-item>
        <div>
          <SparkButton
            class="w-full"
            size="large"
            :loading="state.submitting"
            @click="loadData"
            >Insert data into selected cell
          </SparkButton>
        </div>
      </a-form>
    </div>
  </Loader>
</template>
<style lang="scss">
.theme-table {
  thead th {
    @apply py-2 border-b-1 border-black;
  }

  tbody td {
    @apply py-1;
  }
}
</style>
