<script lang="ts" setup>
import { useAccountsStore } from '@/stores/accounts';
import { ROUTE_NAMES } from '@/router/route-names';


const accountsStore = useAccountsStore();


</script>
<template>
  <header class="bg-green w-full text-white flex p-4 items-center z-1 justify-between relative" style="height:37px;">
    <div class="flex items-center justify-between w-full pr-[30px] font-semibold">
      <router-link :to="{ path: '/' }" class="flex cursor-pointer gap-1 font-display font-semibold items-center">
        <div class="flex items-center gap-1 text-white">
          <SparkIcon icon="logo" class="text-white" size="16" />
          <span style="font-size:16px;">Spark</span>
        </div>
      </router-link>
    </div>
    <section class="flex justify-end">
      <router-link :to="{ name: ROUTE_NAMES.PROFILE }" v-if="accountsStore.accessToken" class="text-white mr-5">
        <div class="p-6 flex gap-1 items-center">
          <SparkIcon size="16" icon="profilefilled" />
        </div>
      </router-link>

    </section>
  </header>
</template>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}
</style>
