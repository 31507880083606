export enum PriceUnit {
    USD_PER_DAY = 'usdPerDay',
    USD_PER_MMBTU = 'usdPerMmbtu',
}

export const PRICE_UNIT_CONFIG = {
    [PriceUnit.USD_PER_DAY]: {
        name: '$/day',
        value: PriceUnit.USD_PER_DAY
    },
    [PriceUnit.USD_PER_MMBTU]: {
        name: '$/MMBtu',
        value: PriceUnit.USD_PER_MMBTU
    }
} as { [key in PriceUnit]: { name: string, value: PriceUnit } };