<script lang="ts" setup>
import { useAccountsStore } from '@/stores/accounts';
import { computed, onMounted, reactive } from 'vue';
import PageHeader from '@/components/common/PageHeader.vue';
import { ROUTE_NAMES } from '@/router/route-names';
import { useRouter } from 'vue-router/composables';

const accountsStore = useAccountsStore();
const router = useRouter();

const state = reactive({
  showDeleteAccountModal: false,
  showChangePasswordModal: false,
  loading: {
    getAccount: false,
    logout: false,
  },
});

const account = computed(() => {
  return accountsStore.commercialAccount;
});


async function logout() {
  state.loading.logout = true;
  accountsStore.logout();
  router.push({ name: ROUTE_NAMES.LOGIN });
}
</script>

<template>
  <div v-if="account" class="p-5 pb-20">
    <PageHeader title="Profile" />
    <header class="flex flex-col gap-2 relative w-full h-full mt-6">
      <h1 class="text-xl h1">{{ account.firstName }} {{ account.lastName }}</h1>
    </header>
    <hr />
    <main class="mt-6">
      <section class="flex flex-col gap-4">
        <div>
          <p class="field-label">Email</p>
          <p>{{ account.email }}</p>
        </div>
        <div class="space-y-2">
          <p class="field-label">Actions</p>
          <SparkButton :loading="state.loading.logout" :rounded="false" @click="logout">
            Logout
          </SparkButton>
        </div>
      </section>

    </main>
  </div>
</template>
<style lang="scss" scoped>
.field-label {
  @apply text-gray-500;
}
</style>
