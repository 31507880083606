import { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { paths } from '#/types/capi-generated';

import ApiBase from './ApiBase';
import { ApiConfig, ApiMeta, GetTokenFunction } from './types';

export function retrieveErrorMessages(errors: any): string[] {
  return errors.response.data.errors.map((e2) => e2.detail);
}

export class CommercialApi extends ApiBase {
  getRefreshTokenFunc: GetTokenFunction = () => null;
  getAccessTokenFunc: GetTokenFunction = () => null;
  refreshTokenFunc?: () => any;
  logoutCallback?: () => void;

  loading = {};

  constructor(
    config: ApiConfig,
    meta: ApiMeta,
    getAccessTokenFunc: GetTokenFunction,
    getRefreshTokenFunc: GetTokenFunction,
    opts?: {
      logoutCallback?: () => void;
      additionalScopes?: string[];
      logPingEvents?: boolean;
      refreshTokenFunc?: () => void;
    },
  ) {
    super(config, meta);

    this.getAccessTokenFunc = getAccessTokenFunc;
    this.getRefreshTokenFunc = getRefreshTokenFunc;
    this.refreshTokenFunc = opts?.refreshTokenFunc;
    this.logoutCallback = opts?.logoutCallback;
  }

  requestInterceptor(
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig {
    const accessToken = this.getAccessTokenFunc();
    if (!config.headers) {
      return config;
    }
    if (config.headers.Authorization === false) {
      delete config.headers.Authorization;
      return config;
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  }

  responseErrorHandler(error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const isAuthRequest = error.config.url?.includes('oauth/token');
    const isRetry = error.config.headers?.retry_attempt;
    if (error.response?.status === 401 && !isAuthRequest && !isRetry) {
      console.log('Retrying');
      return this.refreshTokenFunc()
        .then(() => {
          if (error.config.headers) {
            error.config.headers.retry_attempt = 1;
          }
          return this.$http.request(error.config);
        })
        .catch((err) => {
          console.log('Refresh token failed. Logging out');
          this.logoutCallback?.();
          throw err;
        });
    }

    if (error.response?.status === 401 && isRetry) {
      console.warn('auth error on retry - logging out');
      this.logoutCallback?.();
    }
    return Promise.reject(error);
  }

  async refreshToken() {
    const refreshToken = await this.getRefreshTokenFunc();
    if (!refreshToken) {
      console.warn('No refresh token found. Skipping refresh token.');
      return;
    }

    const response = await this.$http.post(
      'oauth/token/',
      {
        refreshToken,
        grantType: 'refresh_token',
      },
      {
        withCredentials: true,
        headers: {
          Authorization: false,
        },
      },
    );
    return response;
  }

  async loginWithUsernameAndPassword(
    username: string,
    password: string,
    clientId: string,
  ) {
    const path = `/oauth/token/` as keyof paths;
    type operation = paths['/oauth/token/']['post'];
    type successResponse =
      operation['responses'][201]['content']['application/json'];

    const response = await this.$http.post<successResponse>(
      path,
      {
        grantType: 'password',
        clientId,
        username,
        password,
        scopes: 'read:prices,read:access',
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: false,
        },
      },
    );
    const result = response.data;
    return result;
  }

  async getAccount() {
    const path = `/v1.0/me/` as keyof paths;
    type operation = paths['/v1.0/me/']['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.get<successResponse>(path, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const result = response.data;
    return result;
  }

  async fetchPublicPrices(contractId: string) {
    const path =
      `/v1.0/contracts/${contractId}/price-releases/latest/` as keyof paths;
    console.log({ path });
    type operation =
      paths['/v1.0/contracts/{contract_ticker_symbol}/price-releases/latest/']['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.get<successResponse>(path, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const result = response.data.data;
    return result;
  }

  async fetchContracts() {
    const path = `/v1.0/contracts/` as keyof paths;
    type operation = paths['/v1.0/contracts/']['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.get<successResponse>(path, {
      headers: {
        'content-type': 'application/json',
      },
    });
    const result = response.data.data;
    return result;
  }

  async fetchPriceReleases(
    contractTickerSymbol: string,
    vesselType?: string,
    limit = 250,
    offset = 0,
  ) {
    const path =
      `/v1.0/contracts/${contractTickerSymbol}/price-releases/` as keyof paths;
    type operation =
      paths[`/v1.0/contracts/{contract_ticker_symbol}/price-releases/`]['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.get<successResponse>(path, {
      params: { limit, offset, 'vessel-type': vesselType },
      headers: {
        'content-type': 'application/json',
      },
    });
    const result = response.data.data;
    return result;
  }

  async fetchLatestPriceRelease(
    contractTickerSymbol: string,
    vesselType?: string,
  ) {
    const path = `/v1.0/contracts/${contractTickerSymbol}/price-releases/latest/`;
    type operation =
      paths[`/v1.0/contracts/{contract_ticker_symbol}/price-releases/latest/`]['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.get<successResponse>(path, {
      params: {
        'vessel-type': vesselType,
      },
      headers: {
        'content-type': 'application/json',
      },
    });
    const result = response.data.data;
    return result;
  }

  async fetchLatestSparkRCsv() {
    const path = `/beta/sparkr/releases/latest/` as keyof paths;

    const response = await this.$http.get<string>(path, {
      headers: {
        'content-type': 'application/json',
        accept: 'text/csv',
      },
    });
    const result = response.data;
    return result;
  }

  async fetchSparkRListCsv(limit = 250, offset = 0) {
    const path = `/beta/sparkr/releases/` as keyof paths;

    const response = await this.$http.get<string>(path, {
      headers: {
        'content-type': 'application/json',
        accept: 'text/csv',
      },
      params: {
        offset,
        limit,
      },
    });
    const result = response.data;
    return result;
  }
}
