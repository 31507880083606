<script lang="ts" setup>
import KeyNotification from '@/components/notification/KeyNotification.vue';

import { storeToRefs } from 'pinia';

import { useNotificationsStore } from '@/stores/notifications';

const notificationsStore = useNotificationsStore();

const { notifications, keysNotifications, showKeyModal } =
  storeToRefs(notificationsStore);

</script>
<template>
  <div class="relative h-full overflow-y-hidden">
    <div id="notifications-container" class="absolute w-full flex flex-col items-center justify-center z-30">
      <transition-group name="list">
        <KeyNotification v-for="notification in keysNotifications" id="key-notification" :key="notification.id"
          :notification="notification" @click="notificationsStore.removeKeysNotification(notification.id)" />
        <div v-for="notification in notifications" id="notification" :key="notification.id"
          class="bg-white text-gray-100 shadow-lg w-[90%] rounded-lg mx-10 px-5 py-3 my-2 select-none cursor-pointer"
          :class="notification.level" @click="notificationsStore.removeNotification(notification.id)">
          <div class="flex">
            <info-circle-filled v-if="
              notification.level === 'warning' ||
              notification.level === 'info'
            " class="mr-2 mt-[3px]" />
            <!-- <check-circle-filled v-else-if="notification.level === 'success'" class="mr-2 mt-[3px]" />
            <warning-filled v-else-if="notification.level === 'error'" class="mr-2 mt-[3px]" /> -->
            {{notification.content}}
          </div>
        </div>
      </transition-group>
    </div>

    <div v-show="showKeyModal"
      class="absolute bg-shade-dark bg-opacity-50 h-full w-full z-40 text-white flex justify-center items-center cursor-pointer"
      @click="notificationsStore.setShowKeyModal(undefined)">
    </div>
    <div id="mainwindow-body" class="h-full overflow-y-auto">
      <slot />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.success {
  @apply bg-green-600;
}

.error {
  @apply bg-red-500;
}

.info {
  @apply bg-blue-500;
}

.warning {
  @apply bg-yellow-400 text-gray-800;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(-150px);
  opacity: 0;
}
</style>
