import { AnalyticsApi } from '#/interfaces/spark-api/AnalyticsApi'
import { CommercialApi } from '#/interfaces/spark-api/CommercialApi'
import { useAccountsStore } from '@/stores/accounts'

export const appName = `sp-excel-addin-${process.env.NODE_ENV}`
export const rev = `${import.meta.env.VITE_APP_VERSION}-${
  import.meta.env.VITE_APP_BUILD_EPOCH
}`

const baseURL = import.meta.env.VITE_BASE_URL as string

export const analyticsApi = new AnalyticsApi(
  { baseURL },
  {
    appRelease: rev,
    clientAppName: appName,
    getBrowsingSessionId: () => '',
  }
)

const commercialBaseUrl = import.meta.env.VITE_SP_COMMERCIAL_BASE_URL as string

export const commercialApi = new CommercialApi(
  { baseURL: commercialBaseUrl },
  {
    appRelease: rev,
    getBrowsingSessionId: () => '',
    clientAppName: appName,
  },
  () => useAccountsStore().accessToken,
  () => useAccountsStore().refreshToken,
  {
    logoutCallback: () => {
      console.log('logout callback')
      useAccountsStore().logout(true)
    },
    refreshTokenFunc: () => useAccountsStore().doRefreshToken(),
  }
)
