import { Contract } from '@/types/excel-addin'

import { PriceUnit } from './unit'

type ContractQualifierFunction = (contract: Contract) => boolean

export const PRODUCT_CONFIG = {
  'lng-freight': {
    name: 'LNG Freight',
    icon: 'freight-alt-line',
    color: 'green',
    units: [PriceUnit.USD_PER_DAY, PriceUnit.USD_PER_MMBTU],
    qualifierFn: (contract: Contract) =>
      contract.product === 'lng-freight' &&
      contract.type !== 'ffa-monthly' &&
      contract.type !== 'ffa-yearly'
  },
  'lng-freight-ffa': {
    name: 'LNG Freight FFA',
    icon: 'freight-ffa',
    color: 'green',
    units: [PriceUnit.USD_PER_DAY, PriceUnit.USD_PER_MMBTU],
    qualifierFn: (contract: Contract) =>
      contract.product === 'lng-freight' &&
      (contract.type === 'ffa-monthly' || contract.type === 'ffa-yearly')
  },
  'lng-basis': {
    name: 'LNG Basis',
    icon: 'basis',
    color: 'basis',
    units: [PriceUnit.USD_PER_MMBTU],
    qualifierFn: (contract: Contract) => contract.product === 'lng-basis'
  },
  all: {
    name: 'Show All',
    icon: 'freight-alt-line',
    color: 'green',
    units: [PriceUnit.USD_PER_DAY, PriceUnit.USD_PER_MMBTU],
    qualifierFn: (contract: Contract) => true
  }
} as {
  [key: string]: {
    name: string
    icon: string
    color: string
    units: PriceUnit[]
    qualifierFn: ContractQualifierFunction
  }
}
