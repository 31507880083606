import { parse } from 'papaparse'
import { defineStore } from 'pinia'

import { commercialApi } from '@/interfaces/spark-api'
import { ref } from 'vue'

export const useAccessStore = defineStore('access', () => {
  const hasAccessPermission = ref(false)

  async function fetchLatestSparkRCsv(): Promise<{
    columns: string[]
    rows: string[][]
  }> {
    try {

      const terminalsCsv = await commercialApi.fetchLatestSparkRCsv();

      hasAccessPermission.value = true;
      
      const { data, meta, errors } = parse<string>(terminalsCsv, {
        header: true,
        delimiter: ',',
        skipEmptyLines: true
      })
      
      const columns = meta.fields ?? []
      const rows = data
      .map((item) => Object.values(item))
      .filter((item) => item.length === columns.length)
      
      return { columns, rows } as const
    } catch(err){
      console.error(err)
      return { columns: [], rows: [] }
    }
  }

  async function fetchLastNSparkRCsv(n: number): Promise<{
    columns: string[]
    rows: string[][]
  }> {
    const gasHubsCsv = await commercialApi.fetchSparkRListCsv(n, 0)

    const { data, meta, errors } = parse<string>(gasHubsCsv, {
      header: true,
      delimiter: ',',
      skipEmptyLines: true
    })

    const columns = meta.fields ?? []
    const rows = data
      .map((item) => Object.values(item))
      .filter((item) => item.length === columns.length)

    return { columns, rows } as const
  }

  async function fetchAllSparkRCsv(): Promise<{
    columns: string[]
    rows: string[][]
  }> {
    const gasHubsCsv = await commercialApi.fetchSparkRListCsv(999999, 0)

    const { data, meta, errors } = parse<string>(gasHubsCsv, {
      header: true,
      delimiter: ',',
      skipEmptyLines: true
    })

    const columns = meta.fields ?? []
    const rows = data
      .map((item) => Object.values(item))
      .filter((item) => item.length === columns.length)

    return { columns, rows } as const
  }

  return {
    hasAccessPermission,
    fetchLatestSparkRCsv,
    fetchLastNSparkRCsv,
    fetchAllSparkRCsv
  }
})
