import { analyticsApi } from "@/interfaces/spark-api";



export enum EventNames {
  PRICE_POPUP_SEE_MORE_CLICK = 'PRICE_POPUP_SEE_MORE_CLICK',
  PRICE_POPUP_LOGIN_CLICK = 'PRICE_POPUP_LOGIN_CLICK',
  PRICE_POPUP_CLICK = 'PRICE_POPUP_CLICK',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  LOGIN_FORGOT_PASSWORD_CLICK = 'LOGIN_FORGOT_PASSWORD_CLICK',
  LOGIN_CREATE_ACCOUNT_CLICK = 'LOGIN_CREATE_ACCOUNT_CLICK',
  ONBOARD_LOGIN_CLICK = 'ONBOARD_LOGIN_CLICK',
  ONBOARD_CREATE_ACCOUNT_CLICK = 'ONBOARD_CREATE_ACCOUNT_CLICK',
}

const internalAnalyticsTrack = async (event: string, properties: any) => {
  try {
    // const gdprConsent = Cookies.get('gdpr_consent'); // cookie reading here
    const gdprConsent = false;
    const context = {product: 'spark-source'}
    await analyticsApi.postAnalyticsTrack(
      event,
      properties,
      context,
    );
  } catch (err) {
    console.log('postAnalyticsTrack exception: ' + err);
  }
};

export const analyticsPage = function (name: string, properties = {}) {
  // if (!store.getters['auth/isSparkUser']) {
  internalAnalyticsTrack('PAGE_CHANGE', {
    name,
    ...properties,
  });
  // }
};

export const analyticsTrack = function (event: string, properties = {}) {
    internalAnalyticsTrack(event, properties);
};
