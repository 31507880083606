<script setup lang="ts">
import { analyticsTrack, EventNames } from '@/plugins/usage-analytics'

const emit = defineEmits(['login'])

function handleLoginClick() {
  analyticsTrack(EventNames.ONBOARD_LOGIN_CLICK, {})
  emit('login')
}
</script>
<template>
  <section class="w-full flex justify-center text-center pt-6">
    <article class="w-full max-w-md flex flex-col space-y-9 items-center">
      <h1 class="w-full text-2xl font-bold font-display text-center">
        Thank you for downloading Spark Excel Addin
      </h1>
      <p class="w-full text-center text-gray-700">What is Spark?</p>
      <p class="w-full text-center text-gray-700">
        Spark is redefining how the commodity markets understand price. Backed
        by some of the industry’s leading companies including Kpler, ICE and
        EEX, we are working to offer more transparency and greater insight into
        critical parts of the LNG value chain.
      </p>
      <p class="w-full text-center text-gray-700">
        What data can I get with this add-in?
      </p>
      <p class="w-full text-center text-gray-700">
        Your access to data will depend on which type of Spark account you have.
        Full data access to a wide range of price assessments and metrics is
        only available to Spark Premium users (find out more
        <a href="https://www.sparkcommodities.com/#premium" target="_blank"
          >here</a
        >).
      </p>
      <section class="w-full flex gap-8 items-center justify-center my-3">
        <SparkIcon class="text-green" icon="freight-alt-line" size="50" />
        <SparkIcon icon="close" size="16" />
        <SparkIcon class="text-basis" icon="basis" size="50" />
        <SparkIcon icon="close" size="16" />
        <SparkIcon class="text-access" icon="access-logo" size="50" />
      </section>
      <section class="w-full mt-6 w-full px-8">
        <div class="mt-6">
          <SparkButton class="w-full" size="large" @click="handleLoginClick"
            >Login with your Spark account
          </SparkButton>
        </div>
        <div class="mt-6">
          <a
            href="https://app.sparkcommodities.com/signup/?to=products&referer=kpler-terminal"
            target="_blank"
            @click="analyticsTrack(EventNames.ONBOARD_CREATE_ACCOUNT_CLICK, {})"
          >
            <SparkButton class="w-full" size="large" type="secondary"
              >Create your Spark account
            </SparkButton>
          </a>
        </div>
      </section>
    </article>
  </section>
</template>
