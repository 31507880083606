import VueRouter from 'vue-router'
import { authGuard } from './route-guards/auth-guard'

import Login from '@/pages/excel-addin/Login.vue'
import ProfileLite from '@/pages/accounts/ProfileLite.vue'
import ProductSelection from '@/pages/excel-addin/ProductSelection.vue'
import Product from '@/pages/excel-addin/Product.vue'
import Access from '@/pages/excel-addin/Access.vue'
import { ROUTE_NAMES } from './route-names'
import { RouteConstants } from '#/config/route'

const routes = [
  {
    path: '/',
    name: ROUTE_NAMES.LOGIN,
    meta: {
      requireAuth: RouteConstants.ROUTE_ACCESS.REQUIRES_NO_LOGIN,
      transitionLevel: 1,
    },
    component: Login,
  },
  {
    path: '/home',
    name: ROUTE_NAMES.HOME,
    meta: {
      requireAuth: RouteConstants.ROUTE_ACCESS.REQUIRES_LOGIN,
      transitionLevel: 2,
    },
    component: ProductSelection,
  },
  {
    path: '/product/access',
    name: ROUTE_NAMES.ACCESS,
    meta: {
      requireAuth: RouteConstants.ROUTE_ACCESS.REQUIRES_LOGIN,
      transitionLevel: 2,
    },
    component: Access,
  },
  {
    path: '/product/:productId',
    name: ROUTE_NAMES.PRODUCT,
    meta: {
      requireAuth: RouteConstants.ROUTE_ACCESS.REQUIRES_LOGIN,
      transitionLevel: 2,
    },
    component: Product,
  },
  {
    path: '/profile',
    name: ROUTE_NAMES.PROFILE,
    meta: {
      requireAuth: RouteConstants.ROUTE_ACCESS.REQUIRES_LOGIN,
      transitionLevel: 3,
    },
    component: ProfileLite,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach(authGuard)

// Page Analytics
// router.afterEach((to: RouteLocation, from: RouteLocation) => {
//   analyticsPage(to.path, { query: to.query })
// })

export { router }
