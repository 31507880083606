import { PRODUCT_CONFIG } from '@/config/product'
import { commercialApi } from '@/interfaces/spark-api'
import { Contract, PriceRelease, VesselType } from '@/types/excel-addin'

import { defineStore } from 'pinia'

export type ExcelAddinState = {
  contracts: null | Contract[]
  // priceReleasesMap: Map<Contract['id'], PriceRelease[]>
}

export const useExcelAddinStore = defineStore('excel-addin', {
  //   persist:{
  //     enabled: true,
  //     strategies: [{
  //       paths: ['accessToken'],
  //     }]
  //   },
  state: () =>
    ({
      contracts: null,
      // priceReleasesMap: new Map(),
    } as ExcelAddinState),

  actions: {
    async fetchContracts(): Promise<void> {
      try {

        const contracts = await commercialApi.fetchContracts()
        this.contracts = contracts
      } catch(e){
        console.error(e)
        this.contracts = []
      }
    },
    async fetchPriceReleases(
      contractTickerSymbol: string,
      vesselType?: VesselType,
      limit?: number
    ): Promise<PriceRelease[]> {
      const vesselInput =
        vesselType === undefined || vesselType === VesselType.DEFAULT
          ? undefined
          : vesselType

      const priceReleases = await commercialApi.fetchPriceReleases(
        contractTickerSymbol,
        vesselInput,
        limit
      )
      return priceReleases
    },
    async fetchAllPriceReleases(
      contractTickerSymbol: string,
      vesselType?: VesselType
    ): Promise<PriceRelease[]> {
      const vesselInput =
        vesselType === undefined || vesselType === VesselType.DEFAULT
          ? undefined
          : vesselType

      let result = [] as PriceRelease[]
      let lastSize = 0
      let offset = 0
      const LIMIT = 500
      do {
        const priceReleases = await commercialApi.fetchPriceReleases(
          contractTickerSymbol,
          vesselInput,
          LIMIT,
          offset
        )
        lastSize = priceReleases.length
        offset += lastSize
        result = result.concat(priceReleases)
      } while (lastSize === 0 || lastSize === LIMIT)
      return result
    },
    async fetchLatestPriceRelease(
      contractTickerSymbol: string,
      vesselType?: VesselType
    ): Promise<PriceRelease> {
      const vesselInput =
        vesselType === undefined || vesselType === VesselType.DEFAULT
          ? undefined
          : vesselType
      const priceReleases = await commercialApi.fetchLatestPriceRelease(
        contractTickerSymbol,
        vesselInput
      )
      return priceReleases
    },
  },

  getters: {
    contractsByProducts(state) {
      const result = {} as { [key: string]: Contract[] }
      const contracts = state.contracts
      const activeContracts = contracts?.filter((c) => c.status !== 'inactive')
      if (!activeContracts) return result
      Object.keys(PRODUCT_CONFIG).map((productKey) => {
        const productContracts = activeContracts.filter(
          PRODUCT_CONFIG[productKey].qualifierFn
        )
        result[productKey] = productContracts
      })
      return result
    },
  },
})
