import { KeyNotificationType, Notification } from '@/types/notification'

import { defineStore } from 'pinia'

export type NotificationsState = {
  notifications: Notification[]
  keysNotifications: KeyNotificationType[]
  showKeyModal?: KeyNotificationType
}

export const useNotificationsStore = defineStore('notifications', {
  state: () =>
    ({
      notifications: [],
      keysNotifications: [],
      showKeyModal: undefined,
    } as NotificationsState),

  actions: {
    removeNotification(id: Notification['id']) {
      const notifications = this.notifications
      if (!notifications || !notifications.length) {
        return
      }
      const index = notifications.findIndex((n) => n.id === id)
      if (index > -1) {
        notifications.splice(index, 1)
        this.notifications = notifications
      }
    },
    removeKeysNotification(id: KeyNotificationType['id']) {
      const keysNotifications = this.keysNotifications
      if (!keysNotifications || !keysNotifications.length) {
        return
      }
      const index = keysNotifications.findIndex((n) => n.id === id)
      if (index > -1) {
        keysNotifications.splice(index, 1)
        this.keysNotifications = keysNotifications
      }
    },
    setShowKeyModal(payload?: KeyNotificationType) {
      this.showKeyModal = payload
    },
    async pushClientNotification(args: {
      message: string
      level: string
      timeoutMs?: number
    }) {
      const id = Math.random()
      this.notifications.push({
        id,
        content: args.message,
        level: args.level || 'info',
        background: 'light',
      } as Notification)

      setTimeout(() => {
        this.removeNotification(id)
      }, args.timeoutMs || 40000)
    },
    async pushNotification(args: {
      topic: string
      extra: any
      message: Notification['content']
      level: Notification['level']
      timeoutMs?: number
    }) {
      const id = Math.random()
      if (args.topic === 'source:loyalty-balance-updated') {
        if (args.extra?.newKeys && args.extra.newKeys > 0) {
          this.showKeyModal = {
            id,
            data: args.extra,
            difference: args.extra.newKeys,
          } as KeyNotificationType
        } else {
          this.keysNotifications.push({
            id,
            data: args.extra,
          })

          setTimeout(() => {
            this.removeKeysNotification(id)
          }, args.timeoutMs || 6000)
        }
      } else {
        this.notifications.push({
          id,
          content: args.message,
          level: args.level,
          background: 'light',
        })
        setTimeout(() => {
          this.removeNotification(id)
        }, args.timeoutMs || 4000)
      }
    },
  },

  getters: {},
})
