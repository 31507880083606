import '@/assets/css/index.postcss'
import '@/assets/css/tailwind.scss'
import '@/assets/css/styles.scss'
import '#/css/antd-custom.less'

import 'proxy-polyfill'

import Vue, { CreateElement } from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Antd, { Spin } from 'ant-design-vue'

import SparkButton from '@/components/generic/SparkButton.vue'
import Loader from '@/components/generic/Loader.vue'
import SparkIcon from '@/components/generic/SparkIcon/index.vue'
import SparkTabs from '@/components/generic/SparkTabs.vue'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import duration from 'dayjs/plugin/duration'

import { router } from '@/router'

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(duration)

// Modify Antd config
Spin.setDefaultIndicator({
  indicator: (h: CreateElement) => {
    return h('a-icon', {
      props: {
        type: 'loading',
        spin: true,
      },
    })
  },
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.component('SparkButton', SparkButton)
Vue.component('Loader', Loader)
Vue.component('SparkIcon', SparkIcon)
Vue.component('SparkTabs', SparkTabs)

Vue.use(pinia)

Vue.use(Antd)

Vue.use(VueRouter)

Vue.use(PiniaVuePlugin)

window.Office.onReady(() => {
  const app = new Vue({
    pinia,
    router,
    render: (h) => h(App),
  }).$mount('#app')
})
