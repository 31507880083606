<script lang="ts" setup>
import { computed } from 'vue';


type Tab = {
  id: string;
  name: string;
}

const props = withDefaults(defineProps<{ tabs: Tab[]; modelValue: Tab['id'], customTabBackground?: boolean }>(), {
  customTabBackground: false,
})

const emit = defineEmits(['update:modelValue'])

const selectedIndex = computed(() => {

  const selectedIndex = props.tabs.findIndex((tab) => tab.id === props.modelValue);

  return selectedIndex === -1 ? 0 : selectedIndex;
})


const highlightLeftPosition = computed(() => {

  const totalCount = props.tabs.length;
  const position = selectedIndex.value;

  const leftPosition = (100 / totalCount) * position;

  return leftPosition + '%';
})


const highlightLeftWidth = computed(() => {

  const totalCount = props.tabs.length;

  const width = 100 / totalCount;

  return width + '%';
})


function handleClick(tabId: string) {
  emit('update:modelValue', tabId);
}

</script>

<template>
  <nav :class="{ 'bg-green-100 p-1': !customTabBackground }">
    <div class="w-full rounded flex relative select-none">
      <div class="absolute w-full h-full z-0 transition-all duration-300"
        :style="{ left: highlightLeftPosition, width: highlightLeftWidth }">
        <slot name="highlight-block">
          <div class="bg-green rounded h-full w-full"></div>
        </slot>
      </div>
      <div v-for="(tab, $index) in tabs" :key="$index" class="flex-1 cursor-pointer z-10 text-center md:px-3"
        :class="{ 'active-tab': selectedIndex === $index }" @click="handleClick(tab.id)">
        <slot :tab="tab">
          <button :class="[{ selected: selectedIndex === $index }]">
            <span class="
                  flex
                  items-center
                  justify-center
                  font-bold
                  w-full
                  md-bold
                  py-1
                ">
              {{ tab.name }}
            </span>
          </button>
        </slot>
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
.selected {
  @apply text-white;
}
</style>
