import { components } from '#/types/capi-generated'

export type Contract = components['schemas']['Contract']

export type PriceRelease = components['schemas']['PriceRelease']

export enum VesselType {
  DEFAULT = 'default',
  V160 = '160-tfde',
  V174 = '174-2stroke',
}

export type Terminal = components["schemas"]["CAPIAccessTerminalDTOAttr"];
export type GasHub = components['schemas']['CAPIAccessGasHubs']