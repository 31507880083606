<script setup lang="ts">
import { compact, every, flatten, forEach, some } from 'lodash'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

import PageHeader from '@/components/common/PageHeader.vue'
import SparkBreadcrumb from '@/components/generic/SparkBreadcrumb.vue'
import { PRODUCT_CONFIG } from '@/config/product'
import { PRICE_UNIT_CONFIG, PriceUnit } from '@/config/unit'
import { convertToExcel } from '@/helpers/excel-addin/convertToExcel'
import { analyticsTrack } from '@/plugins/usage-analytics'
import { ROUTE_NAMES } from '@/router/route-names'
import { useMainStore } from '@/stores'
import { useExcelAddinStore } from '@/stores/excel-addin'
import { VesselType } from '@/types/excel-addin'
import { components } from '#/types/capi-generated'

import { generateId } from './utils'

const priceReleasesOptions = [
  {
    text: 'Latest',
    value: 'latest'
  },
  {
    text: 'Last n Releases',
    value: 'last-n'
  },
  {
    text: 'All',
    value: 'all'
  }
]

const lastNReleaseNumber = ref(30)

type StateContract = components['schemas']['Contract'] & {
  checked: boolean
}

const vesselOptions = [
  {
    text: 'Default',
    value: VesselType.DEFAULT
  },
  {
    text: '160',
    value: VesselType.V160
  },
  {
    text: '174',
    value: VesselType.V174
  }
]

const state = reactive<{
  checkedList: string[]
  contracts: null | StateContract[]
  priceUnit: PriceUnit
  priceReleasesFilter: string
  vesselType: VesselType
  submitting: boolean
  errors: string[]
}>({
  vesselType: VesselType.DEFAULT,
  checkedList: [] as string[],
  priceUnit: PriceUnit.USD_PER_DAY,
  priceReleasesFilter: 'latest',
  contracts: null,
  submitting: false,
  errors: [] as string[]
})

const excelAddinStore = useExcelAddinStore()
const mainStore = useMainStore()

const route = useRoute()

const productId = computed(() => {
  const productId = route.params.productId
  if (typeof productId === 'string') {
    return productId
  } else {
    return 'lng-freight'
  }
})

const productConfig = computed(() => {
  return PRODUCT_CONFIG[productId.value]
})

const priceUnitOptions = computed(() => {
  return Object.values(PRICE_UNIT_CONFIG).map((unitConfig) => {
    return {
      name: unitConfig.name,
      value: unitConfig.value,
      disabled: !productConfig.value.units.includes(unitConfig.value)
    }
  })
})

const showVesselTypeForm = computed(() => {
  return (
    productId.value === 'lng-freight' ||
    productId.value === 'lng-freight-ffa' ||
    productId.value === 'all'
  )
})

const loading = computed(() => {
  return !excelAddinStore.contracts
})

const allChecked = computed(() => {
  return (
    state.contracts && every(state.contracts, (c: StateContract) => c.checked)
  )
})

const someChecked = computed(() => {
  return some(state.contracts, (c: StateContract) => c.checked)
})

const onCheckAllChange = (e: any) => {
  if (e.target.checked) {
    state.contracts?.forEach((c) => (c.checked = true))
  } else {
    state.contracts?.forEach((c) => (c.checked = false))
  }
}

function loadContracts() {
  if (!excelAddinStore.contracts) {
    return
  }
  const productContracts = excelAddinStore.contractsByProducts[productId.value]
  const processedContracts = productContracts.map((c) => {
    return {
      ...c,
      checked: false
    }
  })
  state.contracts = processedContracts
}

async function excelClearTable(
  context: Excel.RequestContext,
  tableName: string
) {
  const sheet = context.workbook.worksheets.getActiveWorksheet()

  sheet.tables.getItem(tableName).delete()
  sheet.activate()

  await context.sync()
}

async function loadPriceReleases() {
  state.submitting = true
  // excelClearTable()
  if (!state.contracts) {
    return
  }
  const selectedContracts = state.contracts.filter((c) => c.checked)
  try {
    const priceReleasesByContracts = await Promise.all(
      selectedContracts.map(async (c) => {
        let priceReleases
        if (state.priceReleasesFilter === 'latest') {
          priceReleases = [
            await excelAddinStore
              .fetchLatestPriceRelease(c.id, state.vesselType)
              .catch((err) => {
                console.error(err)
                return null
              })
          ]
        } else if (state.priceReleasesFilter === 'all') {
          priceReleases = await excelAddinStore
            .fetchAllPriceReleases(c.id, state.vesselType)
            .catch((err) => {
              console.error(err)
              return null
            })
        } else if (state.priceReleasesFilter === 'last-n') {
          priceReleases = await excelAddinStore
            .fetchPriceReleases(
              c.id,
              state.vesselType,
              lastNReleaseNumber.value
            )
            .catch((err) => {
              console.error(err)
              return null
            })
        }

        return {
          ...c,
          priceReleases
        }
      })
    )

    state.errors = []
    const rangeValues = compact(
      flatten(
        priceReleasesByContracts.map((c) => {
          if (c.priceReleases) {
            return convertToExcel(c, compact(c.priceReleases), state.priceUnit)
          } else {
            state.errors.push(
              `No Permission to see selected ${c.fullName} data. This contract has been omitted from the table.`
            )
            return null
          }
        })
      )
    )
    pasteTableOntoExcel(selectedContracts, rangeValues)
  } finally {
    state.submitting = false
  }
}

function pasteTableOntoExcel(
  selectedContracts: StateContract[],
  rangeValues: (string | number)[][]
) {
  try {
    if (!window.Excel) {
      console.warn('Excel is not loaded')
      return
    }

    window.Excel.run(async (context) => {
      const workbook = context.workbook
      const sheet = workbook.worksheets.getActiveWorksheet()
      sheet.load('tables')
      await context.sync()
      const existingTableCollection = sheet.tables
      const existingTables = existingTableCollection.items

      const activeRange = context.workbook.getSelectedRange()
      const firstCell = activeRange.getCell(0, 0)

      const COLUMNS = [
        'release date',
        'contract',
        'Sub Contract',
        'Sub Contract Type',
        'Period Name',
        'Period Start',
        'Period End',
        'Calendar Month',
        'You',
        'Spark',
        'Spark Min',
        'Spark Max',
        'Portfolio Player',
        'Portfolio Player Min',
        'Portfolio Player Max',
        'Ship Owner',
        'Ship Owner Min',
        'Ship Owner Max'
      ]

      const COLUMNS_COUNT = COLUMNS.length
      const ROWS_COUNT = rangeValues.length

      const tableOccupyRange = firstCell.getAbsoluteResizedRange(
        ROWS_COUNT,
        COLUMNS_COUNT
      )

      // clear existing tables in current cell
      if (existingTables) {
        for (const existingTable of existingTables) {
          const range = existingTable.getRange()
          const intersection =
            range.getIntersectionOrNullObject(tableOccupyRange)
          try {
            await context.sync()
          } catch (err) {
            state.errors.push(JSON.stringify(err))
          }
          if (!intersection.isNullObject) {
            await excelClearTable(context, existingTable.name)
          }
        }
      }

      const TABLE_NAME = 'ContractTable' + generateId()

      const tableRange = firstCell.getAbsoluteResizedRange(1, COLUMNS_COUNT)
      const contractsTable = sheet.tables.add(tableRange, true /* hasHeaders */)
      contractsTable.name = TABLE_NAME
      contractsTable.getHeaderRowRange().values = [COLUMNS]

      // Styles
      contractsTable.style = 'TableStyleLight1'
      contractsTable.getHeaderRowRange().format.fill.color = '#21C08E'
      contractsTable.getHeaderRowRange().format.font.color = '#FFFFFF'
      contractsTable.getHeaderRowRange().format.font.size = 12
      contractsTable.getHeaderRowRange().format.font.name = 'Mont'
      contractsTable.getHeaderRowRange().format.font.bold = true

      contractsTable.getDataBodyRange().format.fill.color = '#FFFFFF'
      contractsTable.getDataBodyRange().format.font.color = '#000000'
      contractsTable.getDataBodyRange().format.font.name = 'Roboto'
      contractsTable.getDataBodyRange().format.font.bold = false

      contractsTable.rows.add(
        undefined /* add rows to the end of the table */,
        rangeValues
      )

      if (Office.context.requirements.isSetSupported('ExcelApi', '1.2')) {
        sheet.getUsedRange().format.autofitColumns()
        sheet.getUsedRange().format.autofitRows()
      }

      sheet.activate()
      await context.sync()
    })

    analyticsTrack('RUN_EXCEL_ADDIN', {
      contracts: selectedContracts.map((c) => c.id),
      priceUnit: state.priceUnit,
      priceReleasesFilter: state.priceReleasesFilter
    })
  } catch (err: any) {
    state.errors.push(err.message)
  } finally {
    state.submitting = false
  }
}

const router = useRouter()

watch(
  () => priceUnitOptions.value,
  (oldVal, newVal) => {
    console.log('trigger')
    setDefaultUnit()
  }
)

function setDefaultUnit() {
  state.priceUnit = priceUnitOptions.value.filter(
    (opt) => !opt.disabled
  )[0].value
}

function handleBreadcrumbInput(value: string) {
  router.push({ name: value })
}

onMounted(() => {
  loadContracts()
  setDefaultUnit()
})

watch(
  () => excelAddinStore.contracts,
  (newValue, oldValue) => {
    loadContracts()
  }
)
</script>

<template>
  <Loader :modelValue="mainStore.isLoading">
    <div class="p-5 space-y-10">
      <section class="space-y-6">
        <PageHeader :title="`Get ${productConfig.name} Pricing Data`" />
        <SparkBreadcrumb
          :config="[
            { name: 'Products', icon: 'home-filled', value: ROUTE_NAMES.HOME },
            {
              name: productConfig.name,
              icon: productConfig.icon,
              styleClasses: `bg-${productConfig.color}`
            }
          ]"
          @input="handleBreadcrumbInput"
        />
      </section>
      <a-form layout="vertical">
        <a-form-item label="Please select the contracts to include">
          <div class="px-3 py-1 border-1 border-gray-300 bg-white">
            <table class="table-fixed w-full theme-table">
              <thead>
                <th class="text-left">Contract Id</th>
                <th class="text-right w-[1rem]">
                  <a-checkbox
                    :checked="allChecked"
                    @change="onCheckAllChange"
                    :disabled="loading"
                  >
                  </a-checkbox>
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="(contract, $index) in state.contracts"
                  class="hover:bg-green-50"
                  :key="$index"
                >
                  <td>{{ contract.id }}</td>
                  <td class="text-right">
                    <a-checkbox v-model:checked="contract.checked"></a-checkbox>
                  </td>
                </tr>
                <tr v-if="!state.contracts">
                  <td colspan="2">
                    <div class="py-5 text-center text-gray-600">
                      Loading contracts...
                    </div>
                  </td>
                </tr>
                <tr v-else-if="!state.contracts.length">
                  <td colspan="2">
                    <div class="py-5 text-center text-gray-600">
                      No contracts found
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </a-form-item>

        <a-form-item
          :colon="false"
          label="Vessel Type"
          v-if="showVesselTypeForm"
        >
          <a-radio-group
            v-model:value="state.vesselType"
            button-style="solid"
            :disabled="loading"
            size="small"
          >
            <a-radio-button
              v-for="opt in vesselOptions"
              :value="opt.value"
              :key="opt.value"
              >{{ opt.text }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :colon="false" label="Price Unit">
          <a-radio-group
            v-model:value="state.priceUnit"
            button-style="solid"
            :disabled="loading"
            size="small"
          >
            <a-radio-button
              v-for="opt in priceUnitOptions"
              :value="opt.value"
              :key="opt.value"
              :disabled="opt.disabled"
              >{{ opt.name }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :colon="false" label="Price Releases">
          <a-radio-group
            class="w-full"
            v-model:value="state.priceReleasesFilter"
            :disabled="loading"
            size="small"
          >
            <a-radio-button
              v-for="opt in priceReleasesOptions"
              :value="opt.value"
              :key="opt.value"
              >{{ opt.text }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          v-if="state.priceReleasesFilter === 'last-n'"
          :colon="false"
          label="Number of releases"
        >
          <a-input
            class="w-full"
            v-model:value="lastNReleaseNumber"
            :disabled="loading"
            size="small"
          />
        </a-form-item>
        <!-- show errors -->
        <a-form-item v-if="state.errors.length">
          <div class="text-red-500 space-y-3">
            <a-alert
              :key="error"
              type="error"
              v-for="error in state.errors"
              message="Warning"
              :description="error"
            />
          </div>
        </a-form-item>
        <div>
          <SparkButton
            class="w-full"
            size="large"
            :disabled="!someChecked || loading"
            :loading="state.submitting"
            @click="loadPriceReleases"
            >Insert data into selected cell
          </SparkButton>
        </div>
      </a-form>
    </div>
  </Loader>
</template>
<style lang="scss">
.theme-table {
  thead th {
    @apply py-2 border-b-1 border-black;
  }

  tbody td {
    @apply py-1;
  }
}
</style>
