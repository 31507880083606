<script setup lang="ts">
import { useNotificationsStore } from '@/stores/notifications';
import { useAccountsStore } from '@/stores/accounts';

import { retrieveErrorMsg } from '@/helpers/retrieveErrorMsg';
import Onboard from '@/pages/excel-addin/Onboard.vue';
import { reactive } from 'vue';
import { analyticsTrack, EventNames } from '@/plugins/usage-analytics';
import { ROUTE_NAMES } from '@/router/route-names';
import { useRoute, useRouter } from 'vue-router/composables';

const notificationsStore = useNotificationsStore();
const accountsStore = useAccountsStore();

const route = useRoute();
const router = useRouter();

const state = reactive({
  loading: {
    loggingIn: false,
  },
  showOnboard: true,
  form: {
    username: '',
    password: '',
  },
});

function closeOnboard() {
  console.log('run')
  state.showOnboard = false
}



async function signIn() {
  try {
    state.loading.loggingIn = true;
    await accountsStore.loginCommercial({
      username: state.form.username,
      password: state.form.password,
    });
    state.loading.loggingIn = false;
    analyticsTrack(EventNames.LOGIN_SUCCESS, {});

    // postMessageToParent('checkLoggedInAndFetchAuthPrice', null);

    const toQuery = route.query.to;
    if (toQuery && typeof toQuery === 'string') {
      // notificationsStore.pushClientNotification({
      //   message: 'Redirecting back to ' + toQuery,
      //   level: 'info',
      // });
      router.push({ name: toQuery });
    } else {
      router.push({ name: ROUTE_NAMES.HOME });
    }
  } catch (err: any) {
    state.loading.loggingIn = false;
    analyticsTrack(EventNames.LOGIN_FAIL, { message: retrieveErrorMsg(err) });
    notificationsStore.pushClientNotification({
      message: retrieveErrorMsg(err),
      level: 'error',
    });
    console.error(err);
  }
}
</script>

<template>
  <div class="w-full">
    <Onboard v-if="state.showOnboard" @login="closeOnboard" />
    <Transition name="fade">
      <section v-if="!state.showOnboard" class="w-full h-full flex pt-6">
        <div class="space-y-4 p-5 w-full">
          <h5 class="h5">Login</h5>
          <form @submit.prevent="signIn">
            <div class="mt-5">
              <a-input class="spark-input" autocomplete="username" v-model="state.form.username" required
                placeholder="Work email" />
            </div>
            <div class="mt-5">
              <a-input class="spark-input" autocomplete="current-password" v-model="state.form.password" required
                type="password" placeholder="Password" />
            </div>


            <SparkButton class="w-full mt-5" type="primary" html-type="submit" :loading="state.loading.loggingIn">
              Sign in to Spark
            </SparkButton>
          </form>
          <section class="flex flex-col gap-3">
            <a href="https://app.sparkcommodities.com/password-reset/" target="_blank"
              @click="analyticsTrack(EventNames.LOGIN_FORGOT_PASSWORD_CLICK, {})">
              <SparkButton class="w-full" type="link">
                Forgotten password?
              </SparkButton>
            </a>
            <hr />
            <div class="mt-5">
              <a href="https://app.sparkcommodities.com/signup/?referer=kpler-terminal" target="_blank"
                @click="analyticsTrack(EventNames.LOGIN_CREATE_ACCOUNT_CLICK, {})">
                <SparkButton class="w-full" type="primary" color="blue">
                  Create account
                </SparkButton>
              </a>
            </div>
          </section>
        </div>
      </section>
    </Transition>
  </div>

</template>
<style lang="scss" scoped>
/* we will explain what these classes do next! */
.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.5s ease;
}

.collapse-leave-to {
  height: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>