import { paths } from '../../types/platformapi-generated';
import ApiBase from './ApiBase';

export class AnalyticsApi extends ApiBase {
  async postAnalyticsTrack(
    payload: paths['/v1/analytics']['post']['requestBody']['content']['application/json'],
  ) {
    return this.$http.post(this.config.baseURL + '/v1/analytics', payload, {
      withCredentials: true,
    });
  }

  postAnalyticsConsent = async (gdprConsent: boolean) => {
    try {
      await this.$http.post(
        `${this.config.baseURL}/consent`,
        {
          gdprConsent,
        },
        { withCredentials: true },
      );
    } catch (err) {
      console.error('postAnalyticsConsent exception: ', err);
    }
  };
}
