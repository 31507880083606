<script lang="ts" setup>
import { computed, onMounted } from 'vue'

import { PRODUCT_CONFIG } from '@/config/product'
import { ROUTE_NAMES } from '@/router/route-names'
import { useExcelAddinStore } from '@/stores/excel-addin'
import { useAccessStore } from '@/stores/access'

const excelAddinStore = useExcelAddinStore()
const accessStore = useAccessStore()

const products = computed(() => {
  return Object.entries(excelAddinStore.contractsByProducts).map(
    ([productKey, contracts]) => {
      const productConfig = PRODUCT_CONFIG[productKey]
      return {
        id: productKey,
        name: productConfig.name,
        icon: productConfig.icon,
        color: productConfig.color,
        contracts,
      }
    }
  )
})
</script>
<template>
  <Loader :modelValue="!excelAddinStore.contracts">
    <main class="flex flex-col items-center p-8">
      <h1 class="text-2xl w-full max-w-md mt-2">Select your Product</h1>
      <router-link
        :key="product.id"
        v-for="product in products"
        :to="{ name: ROUTE_NAMES.PRODUCT, params: { productId: product.id } }"
        class="w-full max-w-xs mt-8"
      >
        <SparkButton
          size="xl"
          :color="product.color"
          class="w-full flex items-center"
          :disabled="!product.contracts.length"
        >
          <SparkIcon :icon="product.icon" size="16" />
          <span class="ml-1">{{ product.name }}</span>
        </SparkButton>
      </router-link>
      <div class="border-1 border-gray-300 w-full mt-8"></div>

      <router-link
        :to="{ name: ROUTE_NAMES.ACCESS }"
        class="w-full max-w-xs h-max mt-8"
      >
        <SparkButton
          :disabled="!accessStore.hasAccessPermission"
          size="xl"
          color="access"
          class="w-full flex items-center"
        >
          <div class="relative flex items-center">
            <SparkIcon icon="accessLogo" size="16" />
            <span class="ml-1">Access</span>
          </div>
        </SparkButton>
      </router-link>
    </main>
  </Loader>
</template>
