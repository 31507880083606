<script setup lang="ts">
import MainWindow from '@/components/layout/MainWindow.vue';
import { useMainStore } from '@/stores';

const mainStore = useMainStore();

mainStore.initApp();

</script>
<template>
  <div id="spark-source" class="w-full h-full md:bg-shade-dark md:m-auto md:max-w-lg"
    style="background-color: transparent">
    <MainWindow />
  </div>
</template>
