import { defineStore } from 'pinia'
import { Account, CommercialAccount } from '@/types/accounts'
import { useMainStore } from '.'
import { commercialApi } from '@/interfaces/spark-api'
import { ROUTE_NAMES } from '@/router/route-names'
import { router } from '@/router'

export type AccountState = {
  accessToken?: string
  refreshToken?: string
  account?: Account
  commercialAccount?: CommercialAccount
  initStatus: 'not-done' | 'done' | 'in-progress'
  initPromise?: Promise<void>
}

export const useAccountsStore = defineStore('accounts', {
  persist: {
    storage: localStorage,
    paths: ['accessToken', 'refreshToken'],
  },
  state: () =>
    ({
      initStatus: 'not-done',
      initPromise: undefined,
      accessToken: undefined,
      refreshToken: undefined,
      account: undefined,
      commercialAccount: undefined,
    } as AccountState),

  actions: {
    async init() {
      if (this.initStatus === 'done') return true
      if (this.initStatus === 'in-progress') {
        await this.initPromise
        return
      }
      if (this.initStatus === 'not-done') {
        let resolveFn: any
        this.initStatus = 'in-progress'
        this.initPromise = new Promise((resolve) => (resolveFn = resolve))
        await this.doRefreshToken()
        // await this.getAccount();
        resolveFn()
        this.initStatus = 'done'
      }
    },
    async getAccountCommercial() {
      const result = await commercialApi.getAccount()
      this.commercialAccount = result.data
    },
    async loginCommercial({
      username,
      password,
    }: {
      username: string
      password: string
    }) {
      const clientId = import.meta.env.VITE_EXCEL_CLIENT_ID
      if (!clientId || typeof clientId !== 'string') {
        throw new Error('Missing client Id')
      }
      const result = await commercialApi.loginWithUsernameAndPassword(
        username,
        password,
        clientId
      )
      this.accessToken = result.accessToken
      this.refreshToken = result.refreshToken
      if (result.accessToken) {
        await this.getAccountCommercial()
        await useMainStore().initApp()
      }
      return result
    },
    async doRefreshToken() {
      try {
        const response = await commercialApi.refreshToken()
        // localStorage.setItem(
        //   'sp-source_access-token',
        //   response.data.access_token
        // );
        this.accessToken = response?.data.accessToken
      } catch (err) {
        this.logout(true)
      }
    },
    async logout(redirect?: boolean) {
      // useMainStore().initApp();

      this.accessToken = undefined
      this.refreshToken = undefined
      this.account = undefined
      this.initStatus = 'not-done'
      this.commercialAccount = undefined

      if (redirect) {
        console.log('redirect')
        router.push(ROUTE_NAMES.HOME)
      }
    },
  },

  getters: {
    isLoggedIn(state) {
      return !!state.accessToken
    },
  },
})
