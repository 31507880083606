import { defineStore } from 'pinia';
import { useAccountsStore } from './accounts';
import { useExcelAddinStore } from './excel-addin';
import { useAccessStore } from './access';

const versionString =
  import.meta.env.MODE === 'development'
    ? import.meta.env.VITE_APP_VERSION + '-dev'
    : import.meta.env.VITE_APP_VERSION;

export const useMainStore = defineStore('main', {
  state: () => ({
    debug: import.meta.env.MODE === 'development',
    version: versionString,
    isInitialized: false,
    isOnboarded: false,
  }),
  persist: 
      {
        storage: localStorage,
        paths: ['isOnboarded'],
      },

  actions: {
    async initApp() {
      // general stores
      // if (this.isInitialized){
      //   return true;
      // }
      const accountsStore = useAccountsStore();
      await accountsStore.init();
      if (accountsStore.isLoggedIn){
        await Promise.all([
          accountsStore.getAccountCommercial(),
          useExcelAddinStore().fetchContracts(),
          useAccessStore().fetchLatestSparkRCsv(),
        ])
      }
      this.isInitialized = true;
    },
    setOnboarded(flag: boolean) {
      this.isOnboarded = flag;
    },
  },

  getters: {
    isLoading: (state) => {
      return !state.isInitialized;
    },
  },
});
